import * as React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import Activation from "../../components/activation";
import Feature from "../../components/Feature";
import FeaturesSummary from "../../components/productPage/featuresSummary";
import Layout from "../../components/layout";
import { container } from "./maintenance.module.css";
import { SEO } from "../../components/seo";

const MaintenancePage = ({ data }) => {
  return (
    <Layout pageTitle="Maintenance">
      <Container fluid className={container}>
        <Feature
          title="Effortless Maintenance Management"
          subtitle="Say goodbye to the cumbersome world of spreadsheets and manual logs. Floatist  revolutionizes the way you manage yacht maintenance. With our intuitive app, you can schedule, track, and document every maintenance activity effortlessly from any device. Automated reminders keep you ahead of routine checks and repairs, ensuring that every vessel is sea-worthy and superbly maintained."
          imageData={data.maintenance.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Easy to use task interfaces"
          subtitle="Designed for intuitive navigation, our platform allows staff to quickly create, assign, and update tasks with just a few clicks. Attach photos, set due dates, and assign tasks to team members. The clear and straightforward layout ensures that all team members can efficiently track progress and deadlines, minimizing confusion and maximizing productivity. "
          imageData={data.tasksInterface.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title="Project templates"
          subtitle="Project templates are designed to streamline the management of recurring tasks. This powerful tool allows you to create and save templates for standard maintenance routines or common project sets, which can be quickly deployed whenever needed. By standardizing these processes, you ensure consistency and accuracy across all operations, while also saving valuable time and effort in planning and execution."
          imageData={data.projectTemplates.childImageSharp.gatsbyImageData}
          textSide="right"
        />

        <Feature
          title="Planned maintenance reminders"
          subtitle="Ensure consistent upkeep and extend the lifespan of your fleet with Floatist's Planned Maintenance Reminders. This feature allows you to establish rules for scheduled maintenance, automating the process of tracking and reminding when it’s time for specific tasks. Engineers can easily check off completed tasks directly in the app, ensuring timely and precise maintenance execution. "
          imageData={data.plannedMaintenance.childImageSharp.gatsbyImageData}
          textSide="left"
        />

        <Feature
          title="Integrated task creation"
          subtitle="Create tasks simply and with one click from the different integrated routine checklists and inspection lists."
          imageData={data.easyTaskCreation.childImageSharp.gatsbyImageData}
          textSide="right"
        />
      </Container>

      <FeaturesSummary />
      <Activation />
    </Layout>
  );
};

export default MaintenancePage;

export const Head = () => (
  <SEO
    description="Say goodbye to the cumbersome world of spreadsheets and manual
    logs. Floatist revolutionizes the way you manage yacht maintenance. With our
    intuitive app, you can schedule, track, and document every maintenance
    activity effortlessly from any device."
  />
);

export const query = graphql`
  query {
    maintenance: file(relativePath: { eq: "maintenance.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    tasksInterface: file(relativePath: { eq: "tasks-interface.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    projectTemplates: file(relativePath: { eq: "project-templates.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    plannedMaintenance: file(relativePath: { eq: "planned-maintenance.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    easyTaskCreation: file(relativePath: { eq: "easy-task-creation.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
